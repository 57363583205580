.documentPart {
    display: flex;
}

.documentPart .leftSection {
    min-width: 100px;
}

.documentPart .rightSection {
    min-width: 140px;
}

.documentPart .leftSection > *, .documentPart .rightSection > * {
    display: none;
}
.documentPart:hover .leftSection > *, .documentPart:hover .rightSection > * {
    display: initial;
}

.middleSection {
    flex-grow: 10;
}

.documentPart .rightSection {
    display: flex;
}

.documentPart .rightSection > * {
    align-self: center;
}

.documentPart p {
    font-size: 22px;
}

.xIcon {
    width: 22px;
    position: relative;
    top: -1px;
}

.documentContainer {
    margin: 0 15%;
    margin-top: 50px;
}

.choice {
    display: flex;
    margin-left: 20px;
}

.choice .choiceLeftSection {
    margin-right: 10px;
    font-size: 20px;
    line-height: 29px;
    font-weight: bold;
}

.choice .choiceRightSection {
    display: flex;
    opacity: 0;
    pointer-events: none;
}

.choice:hover .choiceRightSection {
    opacity: 1;
    pointer-events: initial;
}

.choice .choiceRightSection > * {
    align-self: center;
    margin: 0 5px;
}

.searchPanel {
    display: flex;
    flex-direction: column;
    width: 33%;
    position: fixed;
    right: 0px;
    bottom: 10px;
    padding: 10px;
    background: url(background.png);
    border-radius: 5px;
    height: calc(100% - 75px);
    z-index: 10;
}
