.login {
    display: flex;
    justify-content: space-around;
    height: 100vh;
    background: url(background.png);
}

.login h1 {
    font-family: Tahoma;
    font-weight: normal;
    font-size: 40px;
    margin-top: 0px;
}

.login > div {
    align-self: center;
    padding: 45px;
    border-radius: 5px;
    box-shadow: 4px 4px 4px 1px rgba(0, 0, 0, 0.26);
    background: white;
    width: 100%;
    max-width: 360px;
}

.form > div {
    margin: 10px 0;
}
