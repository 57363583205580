body {
    margin: 0;
    font-family: ui-sans-serif, -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, "Apple Color Emoji", Arial, sans-serif, "Segoe UI Emoji", "Segoe UI Symbol";
    color: #102a40;
}

* {
    outline: 0px solid transparent;
}

.noToggle .dropdown-toggle::after {
    display: none;
}

.dotdotdot {
    transform: rotate(90deg);
    display: inline-block;
    line-height: 9px;
    font-size: 31px;
    width: 0px;
    position: relative;
    left: 11px;
    bottom: 5px;
}
